import { AllProblems, ProblemMeta } from '@/types'
import { resetPage } from '../sharedActions'
import { determineProblemType } from '@/utils/functions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../types/redux'

const initialPage: RootState['page'] = {
  id: undefined,
  meta: undefined,
  type: undefined,
  allProblems: { questions: [], articles: []},
  definitions: {},
}

interface SetPageProps {
  problemId: string;
  problemMeta: ProblemMeta;
  allProblems: AllProblems;
  definitions: { [key: string]: string };
}

export const page = createSlice({
  name: 'page',
  initialState: initialPage,
  reducers: {
    setPage: (state, action: PayloadAction<SetPageProps>) => {
      state.id = action.payload.problemId
      state.meta = action.payload.problemMeta
      state.type = determineProblemType(action.payload.problemMeta)
      state.allProblems = action.payload.allProblems
      state.definitions = action.payload.definitions
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPage, (state, action) => {
      return {
        id: undefined,
        meta: undefined,
        type: undefined,
        allProblems: { questions: [], articles: []},
        definitions: {},
      }
    })
  }
})

export default page.reducer
export const { setPage } = page.actions
