import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../types/redux'
import { resetPage } from '../sharedActions'
import submitFeedback from '../thunks/submitFeedback'
import submitCode from '../thunks/submitCode'

const initialUI: RootState['ui'] = {
  tabIndex: 0,
  splitWidth: null,
  submitFeedback: {
    status: 'idle',
    error: null
  },
}

export const ui = createSlice({
  name: 'ui',
  initialState: initialUI,
  reducers: {
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload
    },
    setSplitWidth: (state, action: PayloadAction<number>) => {
      state.splitWidth = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPage, (state, action) => {
        const { splitWidth } = state
        return {
          tabIndex: 0,
          splitWidth,
          submitFeedback: {
            status: 'idle',
            error: null
          },
        }
      })
      .addCase(submitCode.pending, (state, action) => {
        state.tabIndex = 1
      })
      .addCase(submitFeedback.pending, (state, action) => {
        state.submitFeedback.status = 'loading'
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.submitFeedback.status = 'succeeded'
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.submitFeedback.status = 'failure'
      })
  },
})

export default ui.reducer
export const { setTabIndex, setSplitWidth } = ui.actions
