import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../types/redux'

const initialSettings: RootState['settings'] = {
  isVisualizationEnabled: true
}

export const settings = createSlice({
  name: 'settings',
  initialState: initialSettings,
  reducers: {
    setVisualization: (state, action: PayloadAction<boolean>) => {
      state.isVisualizationEnabled = action.payload
    }
  }
})

export default settings.reducer
export const { setVisualization } = settings.actions
