import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import submitCode from '../thunks/submitCode'
import { resetPage } from '../sharedActions'
import { getRawVerdict } from '@/utils/functions'
import { getIdToIndexMap, judgeToTestCase, mergeTestCases, TestType } from './utils'
import { SampleTestCase } from '@/types'
import { RootState } from '../../types/redux'

const initialTestSuite: RootState['testSuite'] = {
  globalVerdict: null,
  sampleTests: [],
  systemTests: [],
  customTests: [],
  submitCode: {
    status: 'idle',
    error: null
  },
  selectedIndex: 0,
  nextCustomID: 0,
}

export const testSuite = createSlice({
  name: 'testSuite',
  initialState: initialTestSuite,
  reducers: {
    setSelectedTestSuiteTab: (state, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload
    },
    setSampleTests: (state, action: PayloadAction<SampleTestCase[]>) => {
      state.sampleTests = action.payload.map((t, i) => ({
        ...t,
        sampleId: i,
        input: t.input.split('\n'),
        expected: t.output.split('\n'),
        isReadOnly: true,
        output: null,
      }))
    },
    addCustomTest: (state) => {
      state.customTests.push({
        input: [],
        isReadOnly: false,
        customId: state.nextCustomID,
        expected: ['Run your code to see results'],
      })
      state.nextCustomID += 1
    },
    deleteCustomTest: (state, action: PayloadAction<number>) => {
      const filtered = state.customTests.filter(t => t.customId !== action.payload)
      state.customTests = filtered

      if (filtered.length === 0) {
        state.nextCustomID = 0
      }
    },
    setCustomTestInput: (state, action: PayloadAction<{ id: number, input: string[] }>) => {
      const { id, input } = action.payload
      for (let i = 0; i < state.customTests.length; ++i) {
        if (state.customTests[i].customId === id) {
          state.customTests[i].input = input
          break
        }
      }
    },
    deleteSystemTest: (state, action: PayloadAction<number>) => {
      state.systemTests = state.systemTests.filter(t => t.systemId !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPage, (state, action) => {
        return {
          globalVerdict: null,
          sampleTests: [],
          systemTests: [],
          customTests: [],
          submitCode: {
            status: 'idle',
            error: null
          },
          selectedIndex: 0,
          nextCustomID: 0,
        }
      })
      .addCase(submitCode.pending, (state, action) => {
        state.submitCode.status = 'loading'

        for (let i = 0; i < state.sampleTests.length; ++i) {
          state.sampleTests[i] = {
            ...state.sampleTests[i],
            output: null,
            stdout: null,
            stderr: null,
            time: null,
            memory: null
          }
        }

        for (let i = 0; i < state.systemTests.length; ++i) {
          state.systemTests[i] = {
            ...state.systemTests[i],
            expected: null,
            output: null,
            stdout: null,
            stderr: null,
            time: null,
            memory: null
          }
        }

        for (let i = 0; i < state.customTests.length; ++i) {
          state.customTests[i] = {
            ...state.customTests[i],
            output: null,
            stdout: null,
            stderr: null,
            time: null,
            memory: null
          }
        }
      })
      .addCase(submitCode.fulfilled, (state, action) => {
        state.submitCode.status = 'succeeded'

        const { global_verdict, sample_tests, system_tests, custom_tests } = action.payload
        const { sampleTests, systemTests, customTests } = state
        const verdict = getRawVerdict(global_verdict.verdict)

        state.globalVerdict = { ...global_verdict, verdict }

        const sampleIdMap = getIdToIndexMap(sampleTests, TestType.Sample)
        for (let t of sample_tests) {
          if (sampleIdMap.has(t.id)) {
            const i = sampleIdMap.get(t.id)
            const res = judgeToTestCase(t, TestType.Sample)
            sampleTests[i] = mergeTestCases(sampleTests[i], res)
          }
        }

        let hasNewSystemTests = false
        const systemIdMap = getIdToIndexMap(systemTests, TestType.System)
        for (let t of system_tests) {
          const res = judgeToTestCase(t, TestType.System)
          if (systemIdMap.has(t.id)) {
            const i = systemIdMap.get(t.id)
            systemTests[i] = mergeTestCases(systemTests[i], res)
          } else {
            hasNewSystemTests = true
            systemIdMap.set(t.id, systemTests.length)
            systemTests.push(res)
          }
        }

        if (hasNewSystemTests) {
          systemTests.sort((a, b) => a.systemId - b.systemId)
        }

        const customIdMap = getIdToIndexMap(customTests, TestType.Custom)
        for (let t of custom_tests) {
          if (customIdMap.has(t.id)) {
            const i = customIdMap.get(t.id)
            const res = judgeToTestCase(t, TestType.Custom)
            customTests[i] = mergeTestCases(customTests[i], res)
          }
        }

        if (verdict !== 0) {
          let firstFailedTab = 0

          for (let i = 0; i < sampleTests.length; ++i) {
            if (sampleTests[i].status !== 0) {
              state.selectedIndex = firstFailedTab
              return state
            }
            firstFailedTab++
          }

          for (let i = 0; i < systemTests.length; ++i) {
            if (systemTests[i].status !== 0) {
              state.selectedIndex = firstFailedTab
              return state
            }
            firstFailedTab++
          }

          for (let i = 0; i < customTests.length; ++i) {
            if (customTests[i].status !== 0) {
              state.selectedIndex = firstFailedTab
              return state
            }
            firstFailedTab++
          }
        }
      })
      .addCase(submitCode.rejected, (state, action) => {
        state.submitCode.status = 'failure'
      })
  }
})

export default testSuite.reducer
export const {
  setSelectedTestSuiteTab,
  setSampleTests,
  addCustomTest,
  deleteCustomTest,
  setCustomTestInput,
  deleteSystemTest
} = testSuite.actions
