import { axiosInstance } from '@/utils/fetch'
import { FormatCodeRequest, FormatCodeResponse } from '@/types'

const FORMAT_CODE = '/judge/format'

const formatCode = async (payload: FormatCodeRequest) => {
  const res = await axiosInstance().post<FormatCodeResponse>(FORMAT_CODE, payload)
  return res.data
}

export { FORMAT_CODE, formatCode as formatCodeApi }
