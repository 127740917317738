import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import formatCode from '../thunks/formatCode'
import { RootState } from '../../types/redux'

const initialEditor: RootState['editor'] = {
  formatCode: {
    status: 'idle',
    error: null
  },
  options: {
    theme: 'dark',
    tabSize: 4,
    fontSize: 16,
    autoClosingBrackets: 'always',
    quickSuggestions: true,
    renderValidationDecorations: 'on',
    renderWhitespace: 'selection',
  }
}

export const editor = createSlice({
  name: 'editor',
  initialState: initialEditor,
  reducers: {
    setEditorTheme: (state, action: PayloadAction<string>) => {
      state.options.theme = action.payload
    },
    setTabSize: (state, action: PayloadAction<number>) => {
      state.options.tabSize = action.payload
    },
    setFontSize: (state, action: PayloadAction<number>) => {
      state.options.fontSize = action.payload
    },
    setAutoClosingBrackets: (state, action: PayloadAction<boolean>) => {
      state.options.autoClosingBrackets = action.payload ? 'always' : 'never'
    },
    setQuickSuggestions: (state, action: PayloadAction<boolean>) => {
      state.options.quickSuggestions = action.payload
    },
    setRenderValidationDecorations: (state, action: PayloadAction<boolean>) => {
      state.options.renderValidationDecorations = action.payload ? 'on' : 'off'
    },
    setRenderWhitespace: (state, action: PayloadAction<'none' | 'boundary' | 'selection' | 'trailing' | 'all'>) => {
      state.options.renderWhitespace = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(formatCode.pending, (state, action) => {
        state.formatCode.status = 'loading'
      })
      .addCase(formatCode.fulfilled, (state, action) => {
        state.formatCode.status = 'succeeded'
      })
      .addCase(formatCode.rejected, (state, action) => {
        state.formatCode.status = 'failure'
      })
  }
})

export default editor.reducer
export const {
  setEditorTheme,
  setTabSize,
  setFontSize,
  setAutoClosingBrackets,
  setQuickSuggestions,
  setRenderValidationDecorations,
  setRenderWhitespace
} = editor.actions
