import { JudgeResult, TestCase } from '@/types'
import { getRawVerdict } from '@/utils/functions'

export enum TestType {
  Sample = 'Sample',
  System = 'System',
  Custom = 'Custom',
}

export const getIdToIndexMap = (tests: TestCase[], type: TestType) => {
  const idMap = new Map<number, number>()
  for (let i = 0; i < tests.length; ++i) {
    const id = getId(tests[i], type)
    idMap.set(id, i)
  }

  return idMap
};

export const judgeToTestCase = (judgeResult: JudgeResult, type: TestType): TestCase => {
  return {
    input: judgeResult.input?.split('\n'),
    output: judgeResult.output?.split('\n'),
    expected: judgeResult.expected?.split('\n'),
    stdout: judgeResult.stdout?.split('\n'),
    stderr: judgeResult.stderr?.split('\n'),
    status: getRawVerdict(judgeResult?.verdict),
    time: judgeResult.time ? judgeResult.time * 1000 : null,
    memory: judgeResult?.memory,
    sampleId: type === TestType.Sample ? judgeResult.id : null,
    systemId: type === TestType.System ? judgeResult.id : null,
    customId: type === TestType.Custom ? judgeResult.id : null,
    isReadOnly: type === TestType.Sample || type === 'System',
  }
}

export const mergeTestCases = (prev: TestCase, next: TestCase): TestCase => {
  return {
    ...next,
    input: next.input ?? prev.input,
    expected: next.expected ?? prev.expected,
  }
}

const getId = (test: TestCase, type: TestType) => {
  if (type === TestType.Sample) {
    return test.sampleId
  } else if (type === TestType.System) {
    return test.systemId
  } else {
    return test.customId
  }
}
