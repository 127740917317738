import { createAsyncThunk } from '@reduxjs/toolkit'
import { FORMAT_CODE, formatCodeApi } from './api/formatCode'
import { FormatCodeRequest, FormatCodeResponse } from '@/types'

type ArgType = FormatCodeRequest

const formatCode = createAsyncThunk<FormatCodeResponse, ArgType>(
  FORMAT_CODE,
  async (args: ArgType) => {
    const res = await formatCodeApi(args)
    return res as FormatCodeResponse
  }
)

export default formatCode
